import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    INavigationChannel,
    IOpenAppData,
    IPerformActionTopicPayload,
    MessageBroker,
    IRedirectOrNativeOpenExternalUrl,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { IActionHandlerFactory } from '../../action-handler/models/IActionHandlerFactory';
import { Navigation } from '../../navigation';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { PendingService } from '../../pending/pending.service';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';

@Service()
export class NavigationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _navigationChannel: INavigationChannel;
    private readonly _navigation: Navigation;
    private readonly _pendingService: PendingService;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('NavigationChannelSubscriber');
        this._navigation = Container.get(Navigation);
        this._navigationChannel = MessageBroker.getInstance().navigation;
        this._navigationChannel.topics.performAction.subscribe(this.onPerformAction.bind(this));
        this._navigationChannel.topics.openApp.subscribe(this.onOpenApp.bind(this));
        this._navigationChannel.topics.openAppPending.subscribe(this.onOpenAppPending.bind(this));
        this._navigationChannel.topics.redirectOrNativeOpenExternalUrl.subscribe(
            this.onRedirectOrNativeOpenExternalUrl.bind(this),
        );
        this._pendingService = Container.get(PendingService);
    }

    private async onPerformAction(data: IPerformActionTopicPayload): Promise<void> {
        this._logger.debug(`[onPerformAction]] start, data: ${JSON.stringify(data)}`);
        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction(data);
    }

    private async onOpenApp(data: IOpenAppData): Promise<void> {
        this._logger.debug(`[onOpenApp]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            await mb2cb.onOpenApp(data);
        }
        await this._navigation.onOpenApp(data);
    }

    private async onOpenAppPending(): Promise<void> {
        this._logger.debug('[onOpenAppPending]] start');
        await this._pendingService.showPending();
    }

    private async onRedirectOrNativeOpenExternalUrl(
        data: IRedirectOrNativeOpenExternalUrl,
    ): Promise<void> {
        this._logger.debug(
            `[onRedirectOrNativeOpenExternalUrl]] start, data: ${JSON.stringify(data)}`,
        );
        await this._navigation.redirectOrNativeOpenExternalUrl(data);
    }
}
