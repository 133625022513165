import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import { ILogger, ILoginSuccessData, MessageBroker, NativeChannel } from '@sparkware/uc-sdk-core';
import { WalletService } from './wallet.service';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../storage/implementations/simple-store';
import { LoaderManager } from '../../loaders/LoaderManager';
import { INativeService } from '../native/models/INativeService';
import { MBRollout } from '../native/enums/consts';
import { WindowToken } from '../../injection-tokens';

@Service()
export class Wallet {
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _walletService: WalletService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _nativeChannel: NativeChannel;
    private readonly _nativeMBRollout: number;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    public constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('Wallet');
        this._walletService = Container.get(WalletService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._nativeChannel = MessageBroker.getInstance().native;

        this._nativeMBRollout = Number(this._localSimpleStoreService.get('NativeMBRollout'));
    }

    public onLoginSuccess = (data: ILoginSuccessData) => {
        if (data?.InitialState?.userContext) {
            const balanceData = {
                AvailableOnBankrollCurrency: data.InitialState.userContext.userBalance,
                BonusBalance: data.InitialState.userContext.bonusBalance,
                RealUserBalance: data.InitialState.userContext.realBR,
            };

            this._walletService.sendBalanceUpdated(balanceData);
        }
    };

    public onBetSuccess = async () => {
        try {
            const isBalancePollingRequired = this._sessionSimpleStoreService.get(
                StorageItemEnum.BalancePollingRequired,
            );
            if (isBalancePollingRequired == 'true') {
                await this._walletService.sendCurrentUserBalanceUpdated();
            }

            const nativeService = await this._nativeServicePromise;
            if (nativeService) {
                if (this._nativeMBRollout >= MBRollout.NativeChannel) {
                    void this._nativeChannel.topics.triggerAppRating.execute(null, {
                        publisher: 'uc-wallet',
                    });
                } else {
                    if (this._window.webkit) {
                        this._window.webkit?.messageHandlers?.callbackHandler?.postMessage({
                            function_name: 'fireAppTrigger',
                        });
                    }
                    if (
                        nativeService.isAndroid &&
                        typeof this._window.WrapperInterface.betPlaced === 'function'
                    ) {
                        this._window.WrapperInterface.betPlaced();
                    }
                }
            }
        } catch (err) {
            this._logger.error(`[_onBetSuccess] failed`, err);
            throw err;
        }
    };

    public onUpdateBalance = async () => {
        this._logger.debug('Begin process update balance topic request');

        await this._walletService.sendCurrentUserBalanceUpdated();

        this._logger.debug('End in processing update balance topic request');
    };
}
