import { Container, Service } from 'typedi';
import { AppState } from '../app-state';
import { ClientIntegrationFacadeToken, UCFData, WindowToken } from '../../injection-tokens';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import {
    FlowComponents,
    IdsOfElements,
    SPINNER_PROMISE_TIMEOUT,
    StaticPaths,
    ViewSource,
} from '../../models/enums/Consts';
import { LoggerProvider } from '../logger';
import {
    AppIdentifiers,
    OpeningMethod,
    AppStateEnum,
    AreaType,
    ActionName,
    TriggerType,
    IRemoveAppContainerDTO,
    IPerformActionTopicPayload,
    INavigationChannel,
    ISessionChannel,
    IUiChannel,
    IViewInjectorChannel,
    MessageBroker,
    ICreateAppContainerData,
    IShowErrorDTO,
    IToggleAppContainerDTO,
    ILogger,
} from '@sparkware/uc-sdk-core';
import { NavigationResultCode, NavigationResultDesciption } from '../navigation/enums/consts';
import { PerformanceMarks, PerformanceMeasures } from '../performance/enums/consts';
import { B2CIntegration } from '../b2c-integration';
import { ClickStreamTrackingProvider } from '../tracking';
import ContentApi from '../../../APIs/ContentApi';
import { IUrlHandlerOptions } from '../router/models/IUrlHandlerOptions';
import IViewInjectorClickStreamEventData from './models/IViewInjectorClickStreamEventData';
import { NotFound } from '../../../Modules/Utils/API/Constants/ApiConstants';
import { PerformanceManager } from '../performance/performance-manager';
import { Router } from '../router';
import { ClientProvider } from '../../models/enums/Consts';
import { UserContextToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import { Utils } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { UserareaService } from '../external/userarea';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import { PageContentManager } from './managers/page-content.manager';
import { UrlUtils } from '../utils/urlUtils';
import { RouterUtils } from '../router/router.utils';
import { IClientIntegrationFacade } from '../client-integration/interfaces/IClientIntegrationFacade';
import { ICMSContentLoader } from './models/ICMSContentLoader';
import { IBIEventHandler } from '../bi/models/IBIEventHandler';
import { SiteSegmentationContext } from '../site-segmentation-context';
import { IAppCatalog } from '../app-catalog/interfaces';
import { LoaderManager } from '../../loaders/LoaderManager';

enum EventType {
    ClientPage = 'Navigate to client ready',
    ContentPage = 'Navigate to Content ready',
}

@Service()
export class ViewInjector {
    private _ucContainer: HTMLDivElement;
    public ActiveView: ViewSource;
    private _userContext: IUserContext;
    private _eventFormatterBuilder: EventFormatterBuilder;
    private _pageContentManager: PageContentManager;
    private _lastApplicationLayerLevel = 0;
    private readonly _logger: ILogger;
    private readonly _window: Window;
    private readonly _channel: IViewInjectorChannel;
    private readonly _clientIntegrationFacade: IClientIntegrationFacade;
    private readonly _navigationChannel: INavigationChannel;
    private readonly _performanceManager: PerformanceManager;
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _uiChannel: IUiChannel;
    private readonly _utils: Utils;
    private readonly componentId: number;
    private readonly publicationId: number;
    private readonly templateId: number;
    private readonly _clientProvider: string;
    private readonly _appState: AppState;
    private readonly _userareaService: UserareaService;
    private readonly _urlUtils: UrlUtils;

    private readonly _routerUtils: RouterUtils;
    private readonly _siteSegmentationContext: SiteSegmentationContext;

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _biEventHandlerPromise(): Promise<IBIEventHandler> {
        return LoaderManager.Instance.BIEventHandlerLoader.Instance;
    }

    private get _cmsContentLoaderPromise(): Promise<ICMSContentLoader> {
        return LoaderManager.Instance.CMSContentLoaderLoader.Instance;
    }

    public get channel(): IViewInjectorChannel {
        return this._channel;
    }

    public get sessionChannel(): ISessionChannel {
        return this._sessionManagerChannel;
    }

    public get navigationChannel(): INavigationChannel {
        return this._navigationChannel;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ViewInjector');
        this._window = Container.get(WindowToken);
        this._logger.debug('Initializing ViewInjector module...');
        this.ShowClient = this.ShowClient.bind(this);
        this.ShowContent = this.ShowContent.bind(this);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        this._userContext = Container.get(UserContextToken);
        this._channel = MessageBroker.getInstance().viewInjector;
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._navigationChannel = MessageBroker.getInstance().navigation;
        this._uiChannel = MessageBroker.getInstance().ui;
        this._appState = Container.get(AppState);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._clientProvider = this._utils.getClientProvider();
        this._performanceManager = Container.get(PerformanceManager);
        this._userareaService = Container.get(UserareaService);
        this._pageContentManager = Container.get(PageContentManager);
        this._routerUtils = Container.get(RouterUtils);
        this._siteSegmentationContext = Container.get(SiteSegmentationContext);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('ViewInjector');
        const { publicationId, templateId, spectateComponentId, componentId } =
            this._window.pageContextManager.getInfraData();
        const isNativeSdk = this._utils.isNativeSDK();
        if (!isNativeSdk) {
            this.publicationId = publicationId;
            this.templateId = templateId;
            this.componentId =
                this._clientProvider !== ClientProvider.Kambi && !!spectateComponentId
                    ? spectateComponentId
                    : componentId;
            this._ucContainer = this._createContainer();
            this.Init();
        }

        this._switchActiveViewOnShowClient = this._switchActiveViewOnShowClient.bind(this);
        this._switchActiveViewOnShowContent = this._switchActiveViewOnShowContent.bind(this);
    }

    Init(): void {
        this._createEmptyComponents();
        this._setFooterContent();
        this.SetActiveView(ViewSource.FirstHit);
    }

    public async ShowClient(
        uri: string,
        options: IUrlHandlerOptions = { clientNavigated: false },
    ): Promise<void> {
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.Disconnect();
        this._toggleCMSContentVisibility(false);
        this._utils.ClearElementWithId(IdsOfElements.UcFullContent);
        this._logger.debug(`ShowClient is being called with uri ${uri}`);
        const markStart = `${PerformanceMarks.ViewInjectorShowClientStart}-${uri}`,
            measureName = `${PerformanceMeasures.ViewInjectorShowClient}-${uri}`;
        this._performanceManager.startWatch(markStart);
        this._observeClientMode();
        this._utils.ShowElementWithId(IdsOfElements.UcSpectateSportClientView);
        let visiblePublished = false;
        if (this.ActiveView === ViewSource.Content) {
            this._toggleSpinner(true, 'showClient');
            visiblePublished = true;
        }
        try {
            await this._utils.PromiseTimeout(
                SPINNER_PROMISE_TIMEOUT,
                this._switchActiveViewOnShowClient(uri, options, () => {
                    this._toggleCMSContentVisibility(true);
                }),
            );
        } catch (err) {
            this._logger.warn(`ShowClient: error:`, err);
        } finally {
            if (visiblePublished) {
                this._toggleSpinner(false, 'showClient');
            }
            const duration = this._performanceManager.getDuration(measureName, markStart);
            this._performanceManager.clearWatches([markStart]);
            this._sendPerformanceNotification(duration, EventType.ClientPage);
        }
    }

    public async ShowContent(
        uri: string,
        options: IUrlHandlerOptions = { clientNavigated: false },
    ): Promise<void> {
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.Disconnect();
        this._toggleCMSContentVisibility(false);
        this._utils.ClearElementWithId(IdsOfElements.UcFullContent);
        this._logger.debug(`ShowContent is being called with uri ${uri}`);
        const markStart = `${PerformanceMarks.ViewInjectorShowContentStart}-${uri}`,
            measureName = `${PerformanceMeasures.ViewInjectorShowContent}-${uri}`;
        this._performanceManager.startWatch(markStart);
        await this._toggleSpinner(true, 'showContent');
        try {
            await this._utils.PromiseTimeout(
                SPINNER_PROMISE_TIMEOUT,
                this._switchActiveViewOnShowContent(uri),
            );
            await this._toggleSpinner(false, 'showContent');
            this._toggleCMSContentVisibility(true);
        } catch (err) {
            this._logger.warn(`ShowContent: error:`, err);
        } finally {
            const duration = this._performanceManager.getDuration(measureName, markStart);
            this._performanceManager.clearWatches([markStart]);
            this._sendPerformanceNotification(duration, EventType.ContentPage);
            const rootUri = this._urlUtils.getNoLangURL(uri);
            if (
                BrandUtils.isPokerClient() &&
                rootUri === StaticPaths.PokerRoot &&
                !options.clientNavigated
            ) {
                this._clientIntegrationFacade.navigatePublish(rootUri);
            }
        }
    }

    public HideClientAndContent = (withFooter: boolean = true) => {
        if (this.ActiveView === ViewSource.Sport) {
            this._clientIntegrationFacade.deactivatePublish();
        }
        this._utils.HideElementWithId(IdsOfElements.UcSpectateSportClientView);
        this._utils.HideElementWithId(IdsOfElements.UcFullContent);
        if (withFooter) {
            this._utils.HideElementWithId(IdsOfElements.UcFooter);
        }
    };

    public ShowClientAndContent = (isClientNavigation: boolean = true) => {
        if (isClientNavigation) {
            this._utils.ShowElementWithId(IdsOfElements.UcSpectateSportClientView);
        }
        this._utils.ShowElementWithId(IdsOfElements.UcFullContent);
        this._utils.ShowElementWithId(IdsOfElements.UcFooter);
    };

    public InjectClient = () => {
        this._logger.debug('Injecting spectate content to sport client...');
        let spectateSportClientView = this._window.document.getElementById(
            IdsOfElements.SpectateSportClientView,
        );
        let spectateSportClientDiv = this._window.document.getElementById(
            IdsOfElements.UcSpectateSportClientView,
        );
        this._utils.MergeElements(
            spectateSportClientView,
            spectateSportClientDiv,
            IdsOfElements.SpectateSportClientView,
            IdsOfElements.UcSpectateSportClientView,
            'none',
        );
        const router = Container.get(Router);
        router.HandleFirstHit();
    };

    public async OnAuthenticationStatusChangeContentUpdate() {
        const cmsContentLoader = await this._cmsContentLoaderPromise;

        if (this.ActiveView === ViewSource.FirstHit) {
            this._logger.debug(
                'UpdateContentAndFooter: No need to update content and footer on first hit',
            );
            return Promise.resolve();
        }
        if (this.ActiveView === ViewSource.Content) {
            this._toggleSpinner(true, 'authenticationChange');
        }
        this._toggleCMSContentVisibility(false);
        this._updateContentAndFooter()
            .then(() => {
                cmsContentLoader.ResolveWhenReady(this.ActiveView).then(() => {
                    this._toggleCMSContentVisibility(true);
                    if (this.ActiveView === ViewSource.Content) {
                        this._toggleSpinner(false, 'authenticationChange');
                    }
                });
            })
            .catch((err) => {
                this._logger.warn(`OnAuthenticationStatusChangeContentUpdate:`, err);
            });
    }

    public CreateAppContainer(data: ICreateAppContainerData): void {
        try {
            this._logger.debug('CreateAppContainer start');
            const currentAppState = this._appState.getAppState(data.appID)?.state;
            this.updateLastApplicationLayerLevel(true);
            this._appState.setAppState(
                data.appID,
                currentAppState,
                this._lastApplicationLayerLevel,
            );
            const createAppContainerData = {
                ...data,
                applicationLayerLevel: this._lastApplicationLayerLevel,
            };
            this._uiChannel.topics.CreateAppContainer.publish(
                { publisher: FlowComponents.ViewInjector },
                createAppContainerData,
            );
        } catch (err) {
            this._logger.error('CreateAppContainer failed', err);
            throw err;
        }
    }

    public ToggleAppContainer(data: IToggleAppContainerDTO) {
        try {
            const { appID, appState } = data;
            this._appState.setAppState(appID, appState);
            this._logger.debug('ToggleAppContainer start');
            this._uiChannel.topics.toggleAppContainer.publish(
                { publisher: FlowComponents.ViewInjector },
                data,
            );
        } catch (err) {
            this._logger.error('ToggleAppContainer failed', err);
        }
    }

    public RemoveAppContainer(data: IRemoveAppContainerDTO) {
        try {
            this._logger.debug('RemoveAppContainer start');

            if (data.canClose) {
                this.updateLastApplicationLayerLevel(false);

                this._appState.setAppState(
                    data.appID,
                    AppStateEnum.Closed,
                    this._lastApplicationLayerLevel,
                );
            }

            const removeAppContainerData = {
                ...data,
                applicationLayerLevel: this._lastApplicationLayerLevel,
            };
            this._uiChannel.topics.RemoveAppContainer.publish(
                { publisher: FlowComponents.ViewInjector },
                removeAppContainerData,
            );
        } catch (err) {
            this._logger.error('RemoveAppContainer failed', err);
            throw err;
        }
    }

    public async ShowApp(appID: string) {
        if (!appID) return;
        const appCatalog = await this._appCatalogPromise;
        const appCatalogData = await appCatalog.getAppCatalogData(appID);
        if (appCatalogData?.app.openingMethod !== OpeningMethod.GNP) {
            this._appState.setAppState(appID, AppStateEnum.Active);
        }
    }

    public async ShowError(data: IShowErrorDTO) {
        this._appState.setAppState(data.appID, AppStateEnum.Error);
        this._uiChannel.topics.ShowAppError.publish(
            { publisher: FlowComponents.ViewInjector },
            data,
        );
    }

    public async ShowMainAppView(actionID: ActionName, pageTitle: string) {
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.Disconnect();
        if (!!pageTitle) {
            this._window.document.title = pageTitle;
        }
        const data: IPerformActionTopicPayload = {
            actionID: actionID,
            correlationID: uuidv4(),
            launchInfo: {
                businessCorrelationID: uuidv4(),
                sequentialCorrelationID: 1,
                channel: {
                    area: AreaType.button,
                    element: 'Default',
                    source: 'Open Poker History',
                },
                appSpecificParameters: {},
                trigger: TriggerType.userSelection,
                containerID: undefined,
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '0.0.0',
                clientVersion: this._window.pageContextManager.getDeviceData().clientVersion,
            },
            actionData: {},
        };
        this._navigationChannel.topics.performAction.publish({ publisher: 'ViewInjector' }, data);
        this.HideClientAndContent(false);
        this.ActiveView = ViewSource.Content;
        this._toggleCMSContentVisibility(false);
        this._utils.ShowElementWithId(IdsOfElements.UcFooter);

        this._injectFooter().finally(() => {
            cmsContentLoader.ResolveWhenReady(ViewSource.Content).then(() => {
                this._toggleCMSContentVisibility(true);
            });
        });
    }

    public SetActiveView = (nextActiveView: ViewSource) => {
        this._logger.debug(`_setActiveView: Active view was set to: ${nextActiveView}`);
        this.ActiveView = nextActiveView;
    };

    public ResetView = () => {
        if (this.ActiveView !== ViewSource.FirstHit) {
            try {
                this._pageContentManager.reset();
            } catch (err) {
                this._logger.warn(`ResetView:`, err);
            }
            this._updateContentSync('');
        }
    };

    public OnClientInitSuccess = () => {
        this._toggleSpinner(false, 'clientInitSuccess');
    };

    private updateLastApplicationLayerLevel(isIncreased): void {
        try {
            this._logger.debug('[updateLastApplicationLayerLevel] start');
            const change = isIncreased ? 1 : -1;
            const newApplicationLayerLevel = this._lastApplicationLayerLevel + change;
            if (newApplicationLayerLevel >= 0) {
                this._lastApplicationLayerLevel = newApplicationLayerLevel;
                this._logger.debug(
                    `[updateLastApplicationLayerLevel] new-level: ${newApplicationLayerLevel}`,
                );
            } else {
                this._lastApplicationLayerLevel = 0;
                this._logger.debug(`[updateLastApplicationLayerLevel] error, reset to 1`);
            }
        } catch (err) {
            this._logger.error('updateLastApplicationLayerLevel failed', err);
            throw err;
        }
    }

    private _toggleSpinner = async (visible: boolean, component: string) => {
        this._logger.debug(`_toggleSpinner: ${component}: visible: ${visible}`);
        await this._userareaService.executeOnload(() => {
            this.channel.topics.SetVisible.publish(
                {
                    publisher: `${FlowComponents.ViewInjector}-${component}`,
                },
                { visible },
            );
        });
    };

    private _injectHiddenContent = () => {
        if (this._urlUtils.isClientMode()) return;
        this._logger.debug('Injecting hidden content to content...');
        let casinoHiddenDiv = this._window.document.getElementById(IdsOfElements.CasinoContent);
        let hiddenDiv = this._window.document.getElementById(IdsOfElements.HiddenContent);
        let contentDiv = this._window.document.getElementById(IdsOfElements.UcFullContent);
        this._utils.MergeElements(
            casinoHiddenDiv ?? hiddenDiv,
            contentDiv,
            IdsOfElements.HiddenContent,
            IdsOfElements.UcFullContent,
            'block',
        );
    };

    private _createContainer(): HTMLDivElement {
        const containerDiv = this._window.document.createElement('div');
        containerDiv.setAttribute('id', IdsOfElements.UcContainer);
        containerDiv.classList.add('transparent-el');
        this._window.document.body.prepend(containerDiv);
        return containerDiv;
    }

    private _sendPerformanceNotification(duration: number, eventName: string) {
        const trackingProvider = Container.get(ClickStreamTrackingProvider);
        if (duration) {
            const eventData: IViewInjectorClickStreamEventData = {
                event: eventName,
                duration: duration,
            };
            this._logger.debug(
                `Sending elastic notification with the following eventData: ${JSON.stringify(
                    eventData,
                )}`,
            );
            const correlationID = this._utils.getCorrelationId();
            const formatter = this._eventFormatterBuilder.createFormatter(
                '_sendPerformanceNotification',
            );
            const event = formatter.formatUCEvent(
                { message: eventName, durationInMS: duration },
                { correlationID },
                eventData,
            );
            trackingProvider.sendEventV2(event);
        }
    }

    private _getFooter = async (
        publicationId: number,
        templateId: number,
        componentId: number,
    ): Promise<string> => {
        const siteSegmentation = await this._siteSegmentationContext.getString();
        return ContentApi.GetFooter(publicationId, templateId, componentId, siteSegmentation);
    };

    private _injectFooter = async () =>
        this._pageContentManager.FooterDO.promise
            .then((footer) => {
                this._logger.debug('Injecting footer...');
                let footerDiv = this._window.document.getElementById(IdsOfElements.UcFooter);
                if (footerDiv) {
                    footerDiv.innerHTML = footer;
                    this._utils.EvaluateScripts(footer);
                } else this._logger.error(`${IdsOfElements.UcFooter} not found`);
            })
            .catch((reason) => {
                this._logger.error(`_injectFooter: Failed to inject the footer, reason: ${reason}`);
            });

    private _toggleCMSContentVisibility(visible: boolean) {
        const staticLogger = Container.get(LoggerProvider).getLogger('ViewInjector-static');
        try {
            const cmsContainer = this._window.document.getElementById(IdsOfElements.UcCMSContainer);
            if (!cmsContainer) {
                staticLogger.warn(`${IdsOfElements.UcCMSContainer} does not exist`);
                return;
            }
            if (visible) {
                cmsContainer.classList.remove('transparent-el');
            } else {
                cmsContainer.classList.add('transparent-el');
            }
        } catch (error) {
            staticLogger.warn(`${IdsOfElements.UcCMSContainer} does not exist`, error);
        }
    }

    private _updateTitle = () =>
        this._pageContentManager.TitleDO.promise.then(this._updateTitleSync).catch((reason) => {
            throw new Error(reason);
        });

    private _updateTitleSync = (title) => {
        this._logger.debug(`_updateTitleSync: updating title to: ${title}`);
        if (typeof title !== 'undefined') {
            this._window.document.title = title;
        }
    };

    private _createEmptyComponents = () => {
        this._logger.debug('Creating empty divs...');
        const ucContentContainer = this._window.document.createElement('div');
        ucContentContainer.setAttribute('id', IdsOfElements.UcContentContainer);
        const ucWrapperContainer = this._window.document.createElement('div');
        ucWrapperContainer.setAttribute('id', IdsOfElements.UcWrapperContainer);
        const isClientMode = this._urlUtils.isClientMode();
        if (!isClientMode) ucContentContainer.classList.add('visible--el');
        const ucSpectateSportClientDiv = this._window.document.createElement('div');
        ucSpectateSportClientDiv.setAttribute('id', IdsOfElements.UcSpectateSportClientView);
        const ucFullContentDiv = this._window.document.createElement('div');
        ucFullContentDiv.setAttribute('id', IdsOfElements.UcFullContent);
        const ucFooter = this._window.document.createElement('div');
        ucFooter.setAttribute('id', IdsOfElements.UcFooter);
        const ucCMSContainer = this._window.document.createElement('div');
        ucCMSContainer.setAttribute('id', IdsOfElements.UcCMSContainer);
        ucCMSContainer.append(ucFullContentDiv);
        ucCMSContainer.append(ucFooter);
        this._ucContainer.prepend(ucContentContainer);
        ucContentContainer.prepend(ucCMSContainer);
        ucContentContainer.prepend(ucSpectateSportClientDiv);
        if (!isClientMode) this._ucContainer.append(ucWrapperContainer);
    };

    private _getContentAndTitle = async (uri: string) => {
        const parsedUrl = new URL(uri, this._window.location?.origin);
        const siteSegmentation = await this._siteSegmentationContext.getString();
        const { response, errorResponse } = await ContentApi.GetPageContent(
            parsedUrl.pathname,
            this.publicationId,
            siteSegmentation,
        );

        if (errorResponse?.cancelled) {
            return;
        }

        const content = response?.content || '';
        const title = response?.title || '';
        return { title, content, parsedUrl, response, errorResponse };
    };

    private _getContentAndTitleOnClientPage = async (uri: string) => {
        this._logger.debug(`GetContentAndTitleOnClientPage is being called with uri ${uri}`);
        const { title, content, parsedUrl } = await this._getContentAndTitle(uri);
        const { brandId, subBrandId } = this._window.pageContextManager.getBrandData();
        const { regulationTypeId } = this._window.pageContextManager.getRegulationData();
        const { langIso3 } = this._window.pageContextManager.getLocalizationData();
        let contentResponseTitle = title;

        if (!contentResponseTitle) {
            const { response, errorResponse } = await ContentApi.GetTitle(
                this._urlUtils.getRelativeURL(parsedUrl.pathname, {
                    removeBaseUrl: true,
                    removeLanguage: true,
                }),
                brandId,
                subBrandId,
                regulationTypeId,
                langIso3,
            );

            if (errorResponse?.cancelled) {
                return;
            }
            if (response?.Title) {
                contentResponseTitle = response?.Title;
            }

            if (!contentResponseTitle) {
                this._logger.debug('Title is null or undefined');
                const siteSegmentation = await this._siteSegmentationContext.getString();
                let { response, errorResponse } = await ContentApi.GetPageContent(
                    this._urlUtils.getURLWithBase(StaticPaths.Root),
                    this.publicationId,
                    siteSegmentation,
                );

                if (errorResponse?.cancelled) {
                    return;
                }

                contentResponseTitle = response?.title || '';
            }
        }

        this._pageContentManager.resolveContent(content);
        this._pageContentManager.resolveTitle(contentResponseTitle);
    };

    private _getContentAndTitleOnContentPage = async (uri: string) => {
        this._logger.debug(`GetContentAndTitleOnContentPage is being called with uri ${uri}`);
        const { title, content, parsedUrl, response, errorResponse } =
            await this._getContentAndTitle(uri);
        let contentResponse = content;
        let contentResponseTitle = title;

        if (!response) {
            if (String(errorResponse.status) === NotFound) {
                this._sendNavigationResultEvent(
                    parsedUrl.pathname,
                    false,
                    NavigationResultCode.ContentPageNotFound,
                    NavigationResultDesciption.ContentPageNotFound,
                );

                const siteSegmentation = await this._siteSegmentationContext.getString();
                const { response, errorResponse } = await ContentApi.GetPageContent(
                    StaticPaths.ErrorPage,
                    this.publicationId,
                    siteSegmentation,
                );

                if (errorResponse?.cancelled) {
                    return;
                }

                contentResponse = response?.content || '';
                contentResponseTitle = response?.title || '';
            }
        }

        this._pageContentManager.ContentDO.resolve(contentResponse);
        this._pageContentManager.TitleDO.resolve(contentResponseTitle);
    };

    private _updateContent = () =>
        this._pageContentManager.ContentDO.promise.then(this._updateContentSync).catch((reason) => {
            throw new Error(reason);
        });

    private _updateContentSync = (content) => {
        const startTime = Date.now();
        let contentDiv = this._window.document.getElementById(IdsOfElements.UcFullContent);
        contentDiv.innerHTML = content;
        this._utils.EvaluateScripts(content);
        const elapsedTime = Date.now() - startTime;
        this._logger.debug(`_updateContent: Took ${elapsedTime} ms to update content.`);
    };

    private _observeClientMode(): void {
        const isClientMode = this._urlUtils.isClientMode();
        if (!isClientMode) return;
        this._createHideObserver(Container.get(B2CIntegration).getCookieContainer());
    }

    private _createHideObserver = (elementId: string) => {
        const bodyObserver = new MutationObserver(() => {
            const htmlElement = this._window.document.getElementById(elementId);
            if (!!htmlElement) {
                bodyObserver.disconnect();
                this._utils.HideElementWithId(elementId);
            }
        });
        bodyObserver.observe(this._window.document.documentElement, {
            childList: true,
        });
    };

    private async _sendNavigationResultEvent(
        url: string,
        isOk: boolean,
        errorCode?: number,
        errorDescription?: string,
    ) {
        const biEventHandler = await this._biEventHandlerPromise;
        biEventHandler?.sendNavigationResultEvent(url, isOk, errorCode, errorDescription);
    }

    private _setFooterContent = async (): Promise<string> => {
        this._logger.debug('SetFooterContent: setting footer content...');
        this._getFooter(this.publicationId, this.templateId, this.componentId).then(
            this._pageContentManager.FooterDO.resolve,
        );

        return this._pageContentManager.FooterDO.promise;
    };

    private _switchActiveViewOnShowContent = async (uri: string) => {
        const cmsContentLoader = await this._cmsContentLoaderPromise;

        this._logger.debug(
            `_switchActiveViewOnShowContent: current view source= ${this.ActiveView}`,
        );
        const nextActiveView = ViewSource.Content;
        const currentActiveView = this.ActiveView;
        this.SetActiveView(nextActiveView);

        this._utils.HideElementWithId(IdsOfElements.UcSpectateSportClientView);
        const isClientMode = this._urlUtils.isClientMode();
        if (!isClientMode) {
            switch (currentActiveView) {
                case ViewSource.FirstHit: {
                    const ucfData = Container.get(UCFData);
                    const parsedUrl = new URL(uri, this._window.location?.origin);
                    if (ucfData?.Is404) {
                        this._sendNavigationResultEvent(
                            parsedUrl.pathname,
                            false,
                            NavigationResultCode.ContentPageNotFound,
                            NavigationResultDesciption.ContentPageNotFound,
                        );
                    }
                    this._injectHiddenContent();
                    if (this._userContext.IsAuthenticated) {
                        await this._updateContentAndFooter();
                    } else {
                        this._injectFooter();
                    }
                    delete ucfData?.Is404;
                    break;
                }
                case ViewSource.Content:
                case ViewSource.Sport: {
                    this._clientIntegrationFacade.deactivatePublish();
                    this._utils.HideElementWithId(IdsOfElements.UcSpectateSportClientView);
                    this._getContentAndTitleOnContentPage(uri);
                    await this._updateContent();
                    await this._updateTitle();

                    break;
                }
            }
        }

        await cmsContentLoader.ResolveWhenReady(nextActiveView);
    };

    private _switchActiveViewOnShowClient = async (
        uri: string,
        options?: IUrlHandlerOptions,
        resolveWhenReadyCallback?: () => void,
    ) => {
        const nextActiveView = ViewSource.Sport;
        const isClientMode = this._urlUtils.isClientMode();
        if (!isClientMode) {
            const { clientNavigated } = options || {};
            const ucfData = Container.get(UCFData);
            const is404 = ucfData?.Is404;
            const currentActiveView = this.ActiveView;
            this.SetActiveView(nextActiveView);
            switch (currentActiveView) {
                case ViewSource.FirstHit: {
                    if (!is404) {
                        this._injectHiddenContent();
                        if (this._userContext.IsAuthenticated) {
                            await this._updateContentAndFooter();
                        } else {
                            this._injectFooter();
                        }
                    } else {
                        delete ucfData.is404;
                        this._injectFooter();
                    }
                    break;
                }
                case ViewSource.Content:
                    if (!clientNavigated) {
                        this._clientIntegrationFacade.navigatePublish(uri);
                    }
                    this._getContentAndTitleOnClientPage(uri);
                    await this._updateTitle();
                    await this._updateContent();

                    break;
                case ViewSource.Sport: {
                    if (!clientNavigated) {
                        this._clientIntegrationFacade.navigatePublish(uri);
                    }

                    this._getContentAndTitleOnClientPage(uri);
                    await this._updateTitle();
                    await this._updateContent();
                    break;
                }
            }
        }
        const cmsContentLoader = await this._cmsContentLoaderPromise;
        cmsContentLoader.ResolveWhenReady(nextActiveView).then(resolveWhenReadyCallback);
    };

    private _updateContentAndFooter = async () => {
        if (this._urlUtils.isClientMode()) return;
        this._setFooterContent();
        const url = this._urlUtils.getRelativeURL(
            this._urlUtils.getURLWithoutHost(this._window.location?.href),
        );
        const relativeUrl = this._urlUtils.getURLWithoutHost(url);
        const isClientNavigation = this._routerUtils.isClientNavigation(relativeUrl);
        if (isClientNavigation) {
            this._getContentAndTitleOnClientPage(relativeUrl);
        } else {
            const appCatalog = await this._appCatalogPromise;

            const isAppNavigationData = await appCatalog.getAppNavigationData(relativeUrl);
            if (!isAppNavigationData) {
                this._getContentAndTitleOnContentPage(relativeUrl);
            } else {
                this._pageContentManager.ContentDO.resolve('');
                this._pageContentManager.TitleDO.resolve('');
            }
        }
        this._injectFooter();
        await this._updateTitle();
        await this._updateContent();
    };
}
