import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import {
    IIdentityChannel,
    ILogger,
    ILoginSuccessData,
    ILogoutData,
    ILogoutSuccessData,
    ITopicEnvelope,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { IAppCatalog } from '../../app-catalog/interfaces';
import { IOptimizely } from '../../optimizely/interfaces';
import { IGeolocation } from '../../geolocation/models/IGeolocation';
import { INativeService } from '../../native/models/INativeService';
import { IMiniCashier } from '../../mini-cashier/models';
import { ILoyaltyIndication } from '../../loyalty-indication/models/ILoyaltyIndication';
import { UserContextToken } from '../../../injection-tokens';
import { IUserContext } from '../../user-context/user-context-interface';
import { IIdentity } from '../../identity/models/IIdentity';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IAppLauncher } from '../../app-launcher/models/IAppLauncher';
import { Wallet } from '../../wallet';
import { IUkMigration } from '../../uk-migration/interfaces/IUkMigration';
import { IPostLogin } from '../../post-login/models/IPostLogin';
import { ILoginOptions } from '../../authentication/models';
import { SiteSegmentationContext } from '../../site-segmentation-context';
import { IPushController } from '../../push/models/IPushController';
import { IWebPushService } from '../../web-push/models/IWebPushService';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { RouterService } from '../../router/router.service';

export type ILoginSuccessUCData = ILoginSuccessData & { loginOptions: ILoginOptions };

@Service()
export class IdentityChannelSubscriber {
    private _userContext: IUserContext;
    private readonly _logger: ILogger;
    private readonly _identityChannel: IIdentityChannel;
    private readonly _clientTracking: ClientTracking;
    private readonly _wallet: Wallet;
    private readonly _siteSegmentationContext: SiteSegmentationContext;
    private readonly _authenticationService: AuthenticationService;
    private readonly _routerService: RouterService;

    private get _ukMigrationPromise(): Promise<IUkMigration> {
        return LoaderManager.Instance.UkMigrationLoader.Instance;
    }

    private get _postLoginPromise(): Promise<IPostLogin> {
        return LoaderManager.Instance.PostLoginLoader.Instance;
    }

    private get _identityPromise(): Promise<IIdentity> {
        return LoaderManager.Instance.IdentityLoader.Instance;
    }

    private get _optimizelyPromise(): Promise<IOptimizely> {
        return LoaderManager.Instance.OptimizelyLoader.Instance;
    }

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _geolocationPromise(): Promise<IGeolocation> {
        return LoaderManager.Instance.GeolocationLoader.Instance;
    }

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    protected get _miniCashierPromise(): Promise<IMiniCashier> {
        return LoaderManager.Instance.MiniCashierLoader.Instance;
    }

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _loyaltyIndicationPromise(): Promise<ILoyaltyIndication> {
        return LoaderManager.Instance.LoyaltyIndicationLoader.Instance;
    }

    private get _pushPromise(): Promise<IPushController> {
        return LoaderManager.Instance.PushLoader.Instance;
    }

    private get _webPushServicePromise(): Promise<IWebPushService> {
        return LoaderManager.Instance.WebPushLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('IdentityChannelSubscriber');
        this._userContext = Container.get(UserContextToken);
        this._clientTracking = Container.get(ClientTracking);
        this._wallet = Container.get(Wallet);
        this._siteSegmentationContext = Container.get(SiteSegmentationContext);
        this._authenticationService = Container.get(AuthenticationService);
        this._routerService = Container.get(RouterService);

        this._identityChannel = MessageBroker.getInstance().identity;
        this._identityChannel.topics.logoutSuccess.subscribe(this.onLogoutSuccess.bind(this));
        this._identityChannel.topics.loginSuccess.subscribe(this.onLoginSuccess.bind(this));
        this._identityChannel.topics.logout.subscribe(this.onLogout.bind(this));
        this._identityChannel.topics.preLogoutSuccess.subscribe(this.onPreLogoutSuccess.bind(this));
        this._identityChannel.topics.userAuthenticationFailed.subscribe(
            this.onUserAuthenticationFailed.bind(this),
        );
        this._identityChannel.topics.userAuthenticationSucceeded.subscribe(
            this.onUserAuthenticationSucceeded.bind(this),
        );
    }

    private async onLogoutSuccess(data: ILogoutSuccessData) {
        this._userContext.refresh();

        await this._siteSegmentationContext.setSiteSegmentation();

        (await this._identityPromise).onLogout();

        const optimizely = await this._optimizelyPromise;
        await optimizely?.onLogoutSuccess(data);

        const geolocation = await this._geolocationPromise;
        geolocation?.resetGeolocation();

        const nativeService = await this._nativeServicePromise;
        nativeService?.onLogoutSuccess();

        const loyaltyIndication = await this._loyaltyIndicationPromise;
        loyaltyIndication?.onLogoutSuccess();

        const miniCashier = await this._miniCashierPromise;
        miniCashier?.getMiniCashierDetails();

        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onLogoutSuccess();

        this._clientTracking.onLogoutSuccess();

        await this._routerService._handleMainViewApp();
    }

    private async onLoginSuccess(data: ILoginSuccessUCData) {
        this._logger.debug(`[onLoginSuccess]] start, data: ${JSON.stringify(data)}`);
        this._userContext.refresh();

        await this._siteSegmentationContext.setSiteSegmentation();

        const identity = await this._identityPromise;
        identity?.onLoginSuccess(data);

        const appCatalog = await this._appCatalogPromise;
        await appCatalog?.getAppsCatalog();

        const nativeService = await this._nativeServicePromise;
        nativeService?.onLoginSuccess(data);

        const miniCashier = await this._miniCashierPromise;
        miniCashier?.getMiniCashierDetails();

        this._clientTracking.onLoginSuccess();

        const geolocation = await this._geolocationPromise;
        geolocation?.sendGeolocationStatusOnLogin(data);

        this._wallet.onLoginSuccess(data);

        const ukMigration = await this._ukMigrationPromise;
        await ukMigration?.onSuccessfulLogin(data);

        const webPushService = await this._webPushServicePromise;
        await webPushService?.onLoginSuccess(data);

        const postLogin = await this._postLoginPromise;
        await postLogin.executeActions(data);

        const optimizely = await this._optimizelyPromise;
        await optimizely?.onLoginSuccess(data);

        const pushModule = await this._pushPromise;
        pushModule?.onClientInitSucceeded();
    }

    private async onLogout(data: ILogoutData, envelope: ITopicEnvelope) {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onLogoutMessage(data, envelope);
    }

    private async onPreLogoutSuccess(data: ILogoutSuccessData) {
        const appLauncher = await this._appLauncherPromise;
        await appLauncher.onPreLogoutSuccess(data);
    }

    private async onUserAuthenticationFailed(errorData: any) {
        this._authenticationService.onClientIntegrationLoginResponse(errorData);
    }

    private async onUserAuthenticationSucceeded() {
        this._authenticationService.onClientIntegrationLoginResponse();
    }
}
