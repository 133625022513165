import { IdsOfElements } from '../../models/enums/Consts';
import { NativeUtils } from '../native/native.utils';
import Container, { Service } from 'typedi';
import { ClientsFrameworkOpenServiceProductService } from '../external/clients-framework';
import { Utils } from '../utils';
import { UrlUtils } from '../utils/urlUtils';
import { WindowToken } from '../../injection-tokens';

@Service()
export class CRMService {
    private readonly _cfOpenServiceProductService: ClientsFrameworkOpenServiceProductService;
    private readonly _utilsService: Utils;
    private readonly _urlUtils: UrlUtils;
    private readonly _window: Window;

    constructor() {
        this._window = Container.get(WindowToken);
        this._cfOpenServiceProductService = Container.get(
            ClientsFrameworkOpenServiceProductService,
        );
        this._utilsService = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
    }

    public OpenExternalUrl(url: string, target?: string) {
        const deviceInformation = this._window.pageContextManager.getDeviceData();
        if (deviceInformation.isMobile && deviceInformation.isHybrid) {
            NativeUtils.openExternalUrl(url);
        } else {
            let urlTarget = target;

            if (!urlTarget) {
                const urlDomain = this._urlUtils.getDomain(url);
                const currentDomain = this._urlUtils.getDomain();
                urlTarget = urlDomain !== currentDomain ? undefined : '_self';
            }

            this._utilsService.openWindow(url, urlTarget);
        }
    }

    public OpenRich(campaignId) {
        const { isMobile } = this._window.pageContextManager.getDeviceData();
        const callbackUrl = this._window.location.origin;
        let specificNavigation = encodeURIComponent(
            '{"MarketingCampaignId":"' +
                campaignId +
                '","CallBack":{"Method":"POSTMessage","Target":"' +
                callbackUrl +
                '"}}',
        );
        if (isMobile) {
            if (this._utilsService.findIfIsNative()) {
                specificNavigation = encodeURIComponent(
                    '{"MarketingCampaignId":"' +
                        campaignId +
                        '","CallBack":{"Method":"POSTMessage","Target":"' +
                        callbackUrl +
                        '"},"ExternalLink":{"ActionId":27,"UrlParameterName":"Url"}}',
                );
            }

            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                102,
                specificNavigation,
                '',
                '',
                '',
                '',
                '',
                true,
                false,
            );

            const ucContainer = this._window.document.getElementById(IdsOfElements.UcContainer);
            ucContainer?.style.setProperty('position', 'fixed');
        } else {
            this._cfOpenServiceProductService.clientAutoLogin(
                null,
                102,
                specificNavigation,
                940,
                658,
                null,
            );

            setTimeout(() => {
                const scrollContainer = this._window.document.getElementById(
                    IdsOfElements.UcContentContainer,
                );
                scrollContainer?.classList.add('noScroll');
            }, 0);
        }
    }
}
