import ExternalUtils from "./externalServiceUtils";

export class MessageBrokerUtils {
  static publish = async ({ channel, topic, headers, data }) => {
    const topicToPublish = await ExternalUtils.UCSDKService.getTopicAsync(
      channel,
      topic
    );

    if (topicToPublish) {
      setTimeout(() => {
        topicToPublish.publish(headers, data);
      }, 0);
    } else {
      console.error(
        `[MessageBrokerUtils] - MISSING CHANNEL/TOPIC (C: ${channel}, T: ${topic}))`
      );
    }
  };

  static subscribe = ({ channel, topic, callback }) => {
    if (window.libraryManager?.UCSDKLibrary.isReady) {
      return _subscribeWhenSDKAlreadyLoaded({ channel, topic, callback });
    }

    const subscriptionReadyPromise = (async () => {
      await window.libraryManager?.UCSDKLibrary.ready;
      return _subscribeWhenSDKAlreadyLoaded({ channel, topic, callback });
    })();

    return {
      unsubscribe: async () => {
        const sub = await subscriptionReadyPromise;
        sub?.unsubscribe();
      }
    };
  };

  static publishSync = ({ channel, topic, headers, data }) => {
    const topicToPublish = ExternalUtils.UCSDKService.getTopic(channel, topic);

    if (topicToPublish) {
      topicToPublish.publish(headers, data);
    } else {
      console.error(
        `[MessageBrokerUtils] - MISSING CHANNEL/TOPIC (C: ${channel}, T: ${topic}))`
      );
    }
  };

  static subscribeSync = ({ channel, topic, callback }) => {
    const topicToSubscribe = ExternalUtils.UCSDKService.getTopic(
      channel,
      topic
    );

    if (topicToSubscribe) {
      return topicToSubscribe.subscribe(callback);
    } else {
      console.error(
        `[MessageBrokerUtils] - MISSING CHANNEL/TOPIC (C: ${channel}, T: ${topic}))`
      );
    }

    return null;
  };
}

const _subscribeWhenSDKAlreadyLoaded = ({ channel, topic, callback }) => {
  const topicToSubscribe = ExternalUtils.UCSDKService.getTopic(channel, topic);

  if (topicToSubscribe) {
    return topicToSubscribe.subscribe((...params) =>
      setTimeout(() => callback(...params), 0)
    );
  } else {
    console.error(
      `[MessageBrokerUtils] - MISSING CHANNEL/TOPIC (C: ${channel}, T: ${topic}))`
    );
  }
};
