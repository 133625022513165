import { useSsSelector } from '~SpectateStore';
import {
    selectArticlesEnabled,
    selectBabyslipNotificationHideDelayConfig,
    selectBannersContextPlaceholderId,
    selectBannersEnabled,
    selectBetslipMaxSelectionsConfig,
    selectBetslipAnimationEnabled,
    selectBrandedBetbuilderConfig,
    selectBrandedBetbuilderHash,
    selectBrandedBgVisualisationConfig,
    selectBetslipKeypadAnimationEnabled,
    selectBrandId,
    selectBrandedStatsCenterConfig,
    selectIsCategoryFlagEnabledForSport,
    selectClientInactivityConfig,
    selectContainerSize,
    selectDeviceTypeId,
    selectDidReceivePRDCMessage,
    selectHeartbeatConfig,
    selectIsAndroidApp,
    selectIsBuildInfoEnabled,
    selectIsCompetitionOnlySport,
    selectIsFtd,
    selectIsHybrid,
    selectIsIosApp,
    selectIsMobile,
    selectIsMultiGroupedOnlySport,
    selectIsPromotionPermitted,
    selectIsPromotionRestricted,
    selectIsVip,
    selectLanguageIso,
    selectLazyLoadEnabled,
    selectIsMarketSwitcherSportEnabled,
    selectNumberOfMarketsInAllMarkets,
    selectOnboardingPageEnabled,
    selectOnboardingPageOpen,
    selectOptimizelyActive,
    selectOptimizelyKey,
    selectOsName,
    selectOsVersion,
    selectPageCorrelationId,
    selectRaceSwitcherEnabled,
    selectRecommendationBetPlacementEnabled,
    selectRecommendationBetReceiptEnabled,
    selectRecommendationGlobalEnabled,
    selectRecommendationNumberOfBets,
    selectRecommendationNumberOfBetsThreshold,
    selectRmsData,
    selectSearchEnabled,
    selectSerialData,
    selectSportcallerIframeInfo,
    selectSports,
    selectSportSlugs,
    selectSpotlightSports,
    selectStateInitialized,
    selectStatsCenterConfig,
    selectTtlByKey,
    selectVisualisationsConfig,
    selectAllowLogin,
    selectOddsBoostTabBrandedConfig,
} from '~SpectateStore/selectors/clientStateSelectors';

export const useArticlesEnabled = () => useSsSelector(selectArticlesEnabled);
export const useBannersEnabled = () => useSsSelector(selectBannersEnabled);
export const useBannersPlaceholderId = (viewingContext) => useSsSelector(state => selectBannersContextPlaceholderId(state, viewingContext));
export const useBetslipMaxSelectionsConfig = () => useSsSelector(selectBetslipMaxSelectionsConfig);
export const useBetslipAnimationEnabled = () => useSsSelector(selectBetslipAnimationEnabled);
export const useBrandId = () => useSsSelector(selectBrandId);
export const useBrandedBetbuilderConfig = () => useSsSelector(selectBrandedBetbuilderConfig);
export const useBetslipKeypadAnimationEnabled = () => useSsSelector(selectBetslipKeypadAnimationEnabled);
export const useBrandedBetbuilderHash = () => useSsSelector(selectBrandedBetbuilderHash);
export const useBrandedBgVisualisationConfig = () => useSsSelector(selectBrandedBgVisualisationConfig);
export const useBrandedStatsCenterConfig = () => useSsSelector(selectBrandedStatsCenterConfig);
export const useIsCategoryFlagEnabledForSport = (sportSlug) => useSsSelector(state => selectIsCategoryFlagEnabledForSport(state, sportSlug));
export const useCarouselIconsTtl = () => useSsSelector(state => selectTtlByKey(state, 'carouselIcons'));
export const useClientInactivityConfig = () => useSsSelector(selectClientInactivityConfig);
export const useContainerSize = () => useSsSelector(selectContainerSize);
export const useDeviceTypeId = () => useSsSelector(selectDeviceTypeId);
export const useDidReceivePRDCMessage = () => useSsSelector(selectDidReceivePRDCMessage);
export const useHeartbeatConfig = () => useSsSelector(selectHeartbeatConfig);
export const useIsAndroidApp = () => useSsSelector(selectIsAndroidApp);
export const useIsBuildInfoEnabled = () => useSsSelector(selectIsBuildInfoEnabled);
export const useIsCompetitionsOnlySport = (sportSlug) => useSsSelector(state => selectIsCompetitionOnlySport(state, sportSlug));
export const useIsFtd = () => useSsSelector(selectIsFtd);
export const useIsHybrid = () => useSsSelector(selectIsHybrid);
export const useIsIosApp = () => useSsSelector(selectIsIosApp);
export const useIsMobile = () => useSsSelector(selectIsMobile);
export const useIsMultiGroupedOnlySport = (sportSlug) => useSsSelector(state => selectIsMultiGroupedOnlySport(state, sportSlug));
export const useIsPromotionPermitted = () => useSsSelector(selectIsPromotionPermitted);
export const useIsPromotionRestricted = () => useSsSelector(selectIsPromotionRestricted);
export const useIsVip = () => useSsSelector(selectIsVip);
export const useLanguageIso = () => useSsSelector(selectLanguageIso);
export const useLazyLoadEnabled = () => useSsSelector(selectLazyLoadEnabled);
export const useMarketsDescriptionsTtl = () => useSsSelector(state => selectTtlByKey(state, 'marketsDescriptions'));
export const useIsMarketSwitcherSportEnabled = (sportSlug) => useSsSelector(state => selectIsMarketSwitcherSportEnabled(state, sportSlug));
export const useMarketSwitcherOptionsTtl = () => useSsSelector(state => selectTtlByKey(state, 'marketSwitcherOptions'));
export const useMarketSwitcherSelectionsTtl = () => useSsSelector(state => selectTtlByKey(state, 'marketSwitcherSelections'));
export const useMoreGamesTtl = () => useSsSelector(state => selectTtlByKey(state, 'moreGames'));
export const useNotificationHideDelayConfig = () => useSsSelector(selectBabyslipNotificationHideDelayConfig);
export const useNumberOfMarketsInAllMarkets = () => useSsSelector(selectNumberOfMarketsInAllMarkets);
export const useOnboardingPageEnabled = () => useSsSelector(selectOnboardingPageEnabled);
export const useOnboardingPageOpen = () => useSsSelector(selectOnboardingPageOpen);
export const useOptimizelyActive = () => useSsSelector(selectOptimizelyActive);
export const useOptimizelyKey = () => useSsSelector(selectOptimizelyKey);
export const useOsName = () => useSsSelector(selectOsName);
export const useOsVersion = () => useSsSelector(selectOsVersion);
export const usePageCorrelationId = () => useSsSelector(selectPageCorrelationId);
export const useRaceSwitcherEnabled = () => useSsSelector(selectRaceSwitcherEnabled);
export const useRaceSwitcherTtl = () => useSsSelector(state => selectTtlByKey(state, 'raceSwitcher'));
export const useRecommendationBetPlacementEnabled = () => useSsSelector(selectRecommendationBetPlacementEnabled);
export const useRecommendationBetReceiptEnabled = () => useSsSelector(selectRecommendationBetReceiptEnabled);
export const useRecommendationGlobalEnabled = () => useSsSelector(selectRecommendationGlobalEnabled);
export const useRecommendationNumberOfBets = () => useSsSelector(selectRecommendationNumberOfBets);
export const useRecommendationNumberOfBetsThreshold = () => useSsSelector(selectRecommendationNumberOfBetsThreshold);
export const useRmsData = () => useSsSelector(selectRmsData);
export const useSearchEnabled = () => useSsSelector(selectSearchEnabled);
export const useSerialData = () => useSsSelector(selectSerialData);
export const useSportcallerIframeInfo = () => useSsSelector(selectSportcallerIframeInfo);
export const useSports = () => useSsSelector(selectSports);
export const useSportSlugs = () => useSsSelector(selectSportSlugs);
export const useSportsWidgetTtl = () => useSsSelector(state => selectTtlByKey(state, 'sportsWidget'));
export const useSpotlightSports = () => useSsSelector(selectSpotlightSports);
export const useStateInitialized = () => useSsSelector(selectStateInitialized);
export const useStatsCenterConfig = () => useSsSelector(selectStatsCenterConfig);
export const useVisualisationsConfig = () => useSsSelector(selectVisualisationsConfig);
export const useHomepageWidgetsTtl = () => useSsSelector(state => selectTtlByKey(state, 'homepageWidgets'));
export const useAllowLogin = () => useSsSelector(selectAllowLogin);
export const useOddsBoostTabBrandedConfig = () => useSsSelector(selectOddsBoostTabBrandedConfig);
