import { format, parse } from 'url';
import Container, { Service } from 'typedi';
import { WindowToken } from '../../injection-tokens';
import { BrandUtils } from '../../../Modules/Utils/BrandUtils';
import { Brand, MarketingBrand, NavigateLinkId, StaticPaths } from '../../models/enums/Consts';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';

const languageMapping = new Map([['br', 'pt']]);

@Service()
export class UrlUtils {
    private _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _window: Window;

    constructor() {
        this._window = Container.get(WindowToken);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
    }

    getDomain(url: string = ''): string {
        const formattedURL = new URL(url || '', this._window.location.origin);

        return formattedURL.hostname
            .split('.')
            .splice(formattedURL.hostname.split('.').length - 2)
            .join('.');
    }

    static getMappedLanguage = (lang) => {
        return languageMapping.has(lang) ? languageMapping.get(lang) : lang;
    };

    public getRelativeURL = (
        url,
        options: { removeLanguage?: Boolean; removeBaseUrl?: Boolean } = {
            removeLanguage: false,
            removeBaseUrl: false,
        },
    ): string => {
        if (!url) return url;

        const { removeLanguage, removeBaseUrl } = options || {};

        const urlToBeParsed = (removeLanguage && this.getNoLangURL(url)) || url;

        const parsedUrl = parse(this.getURLWithoutHost(urlToBeParsed));
        parsedUrl.hash = null;

        let returnedUrl = format(parsedUrl);
        if (removeBaseUrl) {
            returnedUrl = this.getClientBaseURL(returnedUrl);
        }

        return returnedUrl;
    };

    public getLangURL = (url) => {
        const {
            langId: CurrentLang,
            defaultLang,
            contentLangId: ContentLang,
        } = this._window.pageContextManager.getLocalizationData();

        const { brandId, marketingBrandId } = this._window.pageContextManager.getBrandData();

        const mappedContentLang = UrlUtils.getMappedLanguage(ContentLang);

        if (BrandUtils.isPokerClient() === true || marketingBrandId === MarketingBrand.MrGreen) {
            return url;
        }

        if (!url || typeof url !== 'string' || brandId === Brand.Sport_CA_ON) return url;

        if (CurrentLang === defaultLang && CurrentLang === mappedContentLang) return url;

        if (
            url.indexOf(`/${defaultLang}/`) >= 0 ||
            url.indexOf(`/${CurrentLang}/`) >= 0 ||
            url.indexOf(`/${mappedContentLang}/`) >= 0
        )
            return url;

        return `/${mappedContentLang}${url}`;
    };

    public getNoLangURL = (url) => {
        if (!url || typeof url !== 'string') return url;
        const contentLangId = this._window.pageContextManager.getLocalizationData().contentLangId;

        return url.replace(`/${contentLangId}/`, '/');
    };

    public getURLWithQueryString = (url: string, queryString: string) => {
        if (!url) return undefined;

        const parsedUrl = parse(url, true);
        const parsedQueryString = parse(`/?${queryString}`, true);

        parsedUrl.search = '';
        parsedUrl.query = {
            ...parsedUrl.query,
            ...parsedQueryString.query,
        };

        return format(parsedUrl);
    };

    public getCurrentUrl = () => {
        return this._window.location?.href;
    };

    public getURLWithSCut = (url) => {
        if (!url) return undefined;

        let sCut = this._window.sCut;
        if (sCut !== null && typeof sCut === 'object') sCut = sCut.queryString();

        if (!sCut || !(typeof sCut === 'string')) return url;

        const parsedUrl = parse(url, true);
        const parsedSCut = parse(`/?${sCut}`, true);

        parsedUrl.search = '';
        parsedUrl.query = {
            ...parsedUrl.query,
            ...parsedSCut.query,
        };

        return format(parsedUrl);
    };

    public getURLWithoutHost = (url: string): string => {
        if (!url) return url;

        return url.replace(/.*\/\/[^\/]*/, '');
    };

    public removeParamsFromQuery = (url: string, parameter) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0; ) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        } else {
            var deepLinkIndex = url.indexOf('#deeplink');
            if (deepLinkIndex !== -1) {
                url = url.substring(0, deepLinkIndex - 1);
            }
        }
        return url;
    };

    public getQueryStringParameterByName = (name): any => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        // search for the parameter value, case insensitive
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i'),
            results = regex.exec(this._window.location.search);

        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    public redirectToUrl = (url) => {
        if (url) this._window.location.href = url;
    };

    public getParameterByName = (name: string, tokenMode?: boolean) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        // search for the parameter value, case insensitive
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i'),
            results = regex.exec(this._window.location?.search);

        if (tokenMode == true) {
            return results === null ? '' : decodeURIComponent(results[1]);
        } else {
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
    };

    public isAutologinRequest = () => {
        return !!this.getQueryStringParameterByName('UserInfo');
    };

    public isClientMode(): boolean {
        let isClientMode = this._window.IsClientMode || false;
        if (!isClientMode) {
            const currentSearchParams = new URLSearchParams(this._window.location?.search);
            isClientMode =
                currentSearchParams.get('LinkId') === NavigateLinkId.ToSportOffers.toString();
            if (isClientMode) {
                this._window.IsClientMode = true;
            }
        }

        return isClientMode;
    }

    public getLinkId(): NavigateLinkId {
        const linkId = this.getQueryStringParameterByName('LinkId');
        const enumProperty = NavigateLinkId[linkId];
        if (enumProperty) {
            return NavigateLinkId[enumProperty];
        }

        return undefined;
    }

    public hasReferrer(): boolean {
        const parsedUrl = parse(this._window.location?.href, true);
        return !!parsedUrl.query?.referrer;
    }

    public clearQueryStringParams(): void {
        this._window.history.replaceState(null, '', this._window.location?.href.split('?')[0]);
    }

    public getDeeplinkHash(): string {
        const parsedUrl = parse(this._window.location?.href, true);

        const urlhash = parsedUrl?.hash?.substr(1);
        if (!!urlhash) {
            const deeplLinkHash = decodeURIComponent(urlhash);
            if (deeplLinkHash.split('=')[0] == 'deeplink') {
                return deeplLinkHash.split('=')[1];
            }
        }

        return null;
    }

    public getNativeUXVersion = () => {
        const parameterName = 'nativeUXVersion';

        let version = this.getParameterByName(parameterName);

        if (!version) version = this._localSimpleStoreService.get(parameterName);

        const versionNumber = parseInt(version);

        return isNaN(versionNumber) ? 0 : versionNumber;
    };

    public historyPushState = (params, title, url) => {
        this._window.history.pushState(params, title, url);
    };

    public historyReplaceState = (title: string, url: string) => {
        this._window.history.replaceState({}, title, url);
    };

    public getClientBaseURL = (relativeURL: string) => {
        if (!relativeURL) return relativeURL;

        let clientPath = this.getNoLangURL(relativeURL);

        const clientBaseUrl = this._window.pageContextManager.getSiteData().clientBaseUrl;
        if (
            clientBaseUrl &&
            clientBaseUrl !== StaticPaths.Root &&
            clientPath.startsWith(clientBaseUrl)
        ) {
            clientPath = clientPath.replace(`${clientBaseUrl}/`, StaticPaths.Root);
        }

        return clientPath;
    };

    public getURLWithBase = (url: string) => {
        if (url === null || url === undefined) return url;
        const clientBaseUrl = this._window.pageContextManager.getSiteData().clientBaseUrl;
        if (clientBaseUrl !== StaticPaths.Root) {
            const relativeURL = this.getRelativeURL(url, {
                removeBaseUrl: true,
                removeLanguage: true,
            });

            return `${clientBaseUrl}${relativeURL}`;
        }

        return url;
    };
}
