import { Container, Service } from 'typedi';
import {
    IAuthenticationResult,
    IRegularAuthenticationService,
    IRegularLoginResponseModel,
} from '../authentication/models';
import {
    ICrmChannel,
    ICtaExternalLinkOpenTopicPayload,
    ILogger,
    INativeChannel,
    ISessionChannel,
    IUiChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { LoggerProvider } from '../logger';
import {
    IWebLoginForm,
    IWebLoginInitObject,
    WebLoginComponent,
    getConfig,
} from './web-login.component';
import { PlayerService } from '../player/player.service';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { WebLoginMode } from './enums/const';
import { ClientsFrameworkRegistrationService } from '../external/clients-framework';
import { GENERIC_LOGIN_ERROR_CODE } from '../../models/enums/Consts';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../storage/implementations/simple-store';
import { IAuthenticationStorage } from '../authentication/models';
import { Navigation } from '../navigation';
import { IMrGreenMigration } from '../mr-green-migration/models/interfaces/IMrGreenMigration';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { UserareaService } from '../external/userarea';
import { Utils } from '../utils';
import { INativeService } from '../native/models/INativeService';
import { LoaderManager } from '../../loaders/LoaderManager';
import { PendingService } from '../pending/pending.service';
import { WindowToken } from '../../injection-tokens';

@Service()
export class WebLoginService {
    private _webLoginComponent: WebLoginComponent;
    private _mode: WebLoginMode;
    public IsFirstTouchLogin: boolean = false;
    public IsShown: boolean = false;
    private _username: string;
    private readonly _window: Window;
    private readonly _logger: ILogger;
    private readonly _nativeChannel: INativeChannel;
    private readonly _sessionChannel: ISessionChannel;
    private readonly _uiChannel: IUiChannel;
    private readonly _crmChannel: ICrmChannel;
    private readonly _cfRegistrationService: ClientsFrameworkRegistrationService;
    private readonly _localStorageItemService: LocalSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _playerService: PlayerService;
    private readonly _userAreaService: UserareaService;
    private readonly _utils: Utils;
    private readonly _pendingService: PendingService;
    protected readonly _navigation: Navigation;
    private readonly _webLoginComponentReady: DeferredObject<void>;
    private readonly _tempWebLoginObject: any;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _mrGreenMigrationPromise(): Promise<IMrGreenMigration> {
        return LoaderManager.Instance.MrGreenMigrationLoader.Instance;
    }

    private get _authenticationStoragePromise(): Promise<IAuthenticationStorage> {
        return LoaderManager.Instance.AuthenticationStorageLoader.Instance;
    }

    private get _regularAuthenticationServicePromise(): Promise<IRegularAuthenticationService> {
        return LoaderManager.Instance.RegularAuthenticationServiceLoader.Instance;
    }

    public get Mode(): WebLoginMode {
        return this._mode;
    }

    public get IsLoaded(): boolean {
        return this._webLoginComponent.IsLoaded;
    }

    public get TempWebLoginObject(): boolean {
        return this._tempWebLoginObject;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('WebLoginService');
        this._nativeChannel = MessageBroker.getInstance().native;
        this._sessionChannel = MessageBroker.getInstance().session;
        this._uiChannel = MessageBroker.getInstance().ui;
        this._crmChannel = MessageBroker.getInstance().crm;
        this._localStorageItemService = Container.get(LocalSimpleStoreService);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._playerService = Container.get(PlayerService);
        this._cfRegistrationService = Container.get(ClientsFrameworkRegistrationService);
        this._userAreaService = Container.get(UserareaService);
        this._navigation = Container.get(Navigation);
        this._utils = Container.get(Utils);
        this._pendingService = Container.get(PendingService);
        this._tempWebLoginObject = this._createTemporaryWebLoginObject();
        this._webLoginComponentReady = new DeferredObject<void>();
        this._username = '';
        this._initializeWebLogin();
    }

    public GetWebLogin = async (): Promise<any> => {
        return this._webLoginComponent.GetWebLogin();
    };

    public Show = async (...params): Promise<any> => {
        await this._webLoginComponentReady.promise;
        const savedUsername = await this._getUsername();
        await this._webLoginComponent.Show(savedUsername, ...params);

        this.toggleScrollActivation(true);
    };

    public ProcessResponse = async (response): Promise<any> =>
        this._webLoginComponent.ProcessResponse(response);

    public Hide = async (...params): Promise<any> => {
        this._webLoginComponent.Hide(...params);

        this.toggleScrollActivation(false);
    };

    public UpdateForm = async (...params): Promise<any> =>
        this._webLoginComponent.UpdateForm(...params);

    public Register = async (...params): Promise<any> =>
        this._webLoginComponent.Register(...params);

    public HandleWebLoginResponse = async (result: IRegularLoginResponseModel): Promise<void> => {
        await this.Show();
        const processData = result?.FullResponse || {
            error: { errormessageid: result?.ErrorCode || GENERIC_LOGIN_ERROR_CODE },
        };

        const processResponseResult = await this._webLoginComponent.ProcessResponse(processData);

        let hasPending: boolean = false;
        for (let i = 0; i < processResponseResult.actionhandler.length; i++) {
            if (processResponseResult.actionhandler[i] == 'caller') {
                switch (result?.ErrorCode) {
                    case 50: // pending
                        this.Hide();

                        if (result.PendingToken) {
                            const pendingObj = {
                                PendingToken: result.PendingToken,
                                SpecificNavigation: result.SpecificNavigation,
                                SubBrand: this._window.pageContextManager.getBrandData().subBrandId,
                            };
                            hasPending = true;
                            this._pendingService.displayPendingOnLogin(
                                pendingObj.PendingToken,
                                pendingObj.SpecificNavigation,
                            );
                        }
                        break;
                    default: {
                    }
                }
            }
        }

        if (result?.BearerToken) {
            this.Hide();
        }
    };

    public ResetLoginForm = async (): Promise<void> => {
        this._mode = WebLoginMode.Regular;

        const username = await this._getUsername();
        this._webLoginComponent.ResetLoginForm(username).then(this.Hide);
    };

    public ShowFitToPlay = async () => {
        const formOptions: IWebLoginForm = { mode: { type: WebLoginMode.FitToPlay } };
        this._mode = WebLoginMode.FitToPlay;
        return this.UpdateForm(formOptions);
    };

    private async _initializeWebLogin(): Promise<void> {
        this._username = await this._getUsername();
        let initObject = await this._getInitObject(this._username);
        const mrGreenMigration = await this._mrGreenMigrationPromise;
        if (mrGreenMigration)
            initObject = mrGreenMigration.SetInitWebLoginObjectProperties(initObject);

        const eventsObject = this._getEventsObject();
        this._mode = initObject.formoptions.mode.type;
        this._webLoginComponent = new WebLoginComponent(initObject, eventsObject);
        this._webLoginComponentReady.resolve();
    }

    private _createTemporaryWebLoginObject = () => ({
        isTemp: true,
        show: (...params: any) => {
            this._logger.warn('This call is deprecated. Use show message instead!');
            //should use the ManualLoginAuthentication.
            this.Show(...params);
        },
        updateform: this.UpdateForm,
        hide: this.Hide,
        registerClicked: this.Register,
    });

    private _getInitObject = async (savedUsername: string): Promise<IWebLoginInitObject> => {
        const formOptions: IWebLoginForm = getConfig(savedUsername);
        const initObject: IWebLoginInitObject = {
            subbrandid: this._window.pageContextManager.getBrandData().subBrandId,
            languagecode: this._window.pageContextManager.getLocalizationData().langIso3,
            productpackageid: this._window.pageContextManager.getDeviceData().productPackage,
            formoptions: formOptions,
            showclosebutton: true,
        };

        const { mitIdSwitchBackUrl } = this._window.pageContextManager.getSiteData();
        if (mitIdSwitchBackUrl && this._utils.findIfIsNative())
            initObject.appSwitch = {
                switchbackurl: mitIdSwitchBackUrl,
                switchbackos: this._window.pageContextManager.getDeviceData().osName.toLowerCase(),
            };

        return initObject;
    };

    private _getEventsObject = () => ({
        onload: this._onLoad,
        onerror: this._onError,
        onexternallink: this._onExternaLink,
        onsubmit: this._onSubmit,
        onregister: this._onRegister,
        onfyp: this._onFYP,
        onfyu: this._onFYU,
        onclose: this._onClose,
        ontouchId: this._onTouchId,
        onupdated: this._onUpdated,
        onredirectlink: this._onRedirectLink,
    });

    private async _saveUsername(username) {
        this._username = '';
        if (!username) return;

        this._localStorageItemService.set(StorageItemEnum.UserName, username);
        this._username = username;
    }

    private _getUsername = async (): Promise<string> => {
        if (this._username) return this._username;

        const userName = this._localStorageItemService.get(StorageItemEnum.UserName);

        return userName ?? undefined;
    };

    //#region Events

    private _onLoad = () => {
        this._logger.debug('_onLoad callback...');
        this._tempWebLoginObject.isTemp = false;
    };

    private _onError = (e: { errortext: any }) => {
        this._logger.error('_onError callback... ', e);
    };

    private _onExternaLink = (e: { target: any }) => {
        this._logger.debug(`_onExternaLink callback...${e}`);
        this._crmChannel.topics.CTA_ExternalLink_Open.publish(
            { publisher: 'UCF-WebLoginService' },
            { Url: e.target } as ICtaExternalLinkOpenTopicPayload,
        );
    };

    private _onSubmit = async (eventData: any) => {
        switch (this.Mode) {
            case WebLoginMode.Regular: {
                const username = eventData.authenticationdata?.credentials?.username;

                const regularAuthenticationService =
                    await this._regularAuthenticationServicePromise;
                const data: IAuthenticationResult<IRegularLoginResponseModel> =
                    await regularAuthenticationService.login(eventData);

                if (data.response.Response.BearerToken && username) {
                    this._saveUsername(username);
                }

                break;
            }
            case WebLoginMode.FitToPlay: {
                this.ResetLoginForm();

                this._nativeChannel.topics.userIsFitToPlay.publish(
                    { publisher: 'UCF-WebLoginService' },
                    {},
                );
                break;
            }
        }
    };

    private _onRegister = () => {
        this._logger.debug('_onRegister callback...');
        const onRegistrationClose = () => {
            this.Show();
        };
        this._playerService
            .openRegister({
                onClose: onRegistrationClose,
            })
            .then(() => {
                this.ResetLoginForm();
            })
            .catch(() => {
                this._logger.warn('_onRegister: openRegisterFlow fails');
            });
    };

    private _onFYP = () => {
        this._logger.debug('_onFYP callback...');
        this._cfRegistrationService.FYP(966, 696);
    };

    private _onFYU = () => {
        this._logger.debug('_onFYU callback...');
        this._cfRegistrationService.FYU(966, 696);
    };

    private _onClose = () => {
        this._logger.debug('_onClose callback...');

        this.ResetLoginForm();

        this._window.document.documentElement.classList.remove('overlayWebLogin');

        this._sessionSimpleStoreService.remove(StorageItemEnum.IsCasinoAutoLogin);

        const canvasWrap = this._window.document.getElementsByClassName('off-canvas-wrap');
        if (canvasWrap?.length > 0) {
            (canvasWrap[0] as HTMLElement).style.display = 'block';
        }

        this._sessionChannel.topics.webLoginClosed.publish(
            { publisher: 'UCF-WebLoginService' },
            {},
        );
        this.toggleScrollActivation(false);
    };

    private _onTouchId = () => {
        this._logger.debug('_onTouchId callback...');
    };

    private _onUpdated = () => {
        this._logger.debug('_onUpdated callback...');
    };

    private _onRedirectLink = (eventData: any) => {
        this._logger.debug('_onRedirectLink callback...');
        this._navigation.redirectOrNativeOpenExternalUrl({ url: eventData.target });
    };

    private toggleScrollActivation = (isDisabled: boolean) => {
        this._userAreaService.executeOnload(() => {
            this._uiChannel.topics.scrollActivation.publish(
                { publisher: 'UCF-WebLoginService' },
                { identifier: 'webLogin', isDisabled, selector: 'body' },
            );
        });
    };

    //#endregion
}
