import Container from 'typedi';
import {
    ClientIntegrationFacadeToken,
    FeatureAvailabilityToken,
    UCFData,
} from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import { SpectateClientIntegrationFacade } from '../spectate-client-integration.facade';
import { IClientSettings, ISpectateClientSettings } from '@sparkware/uc-sdk-core';
import { DeviceUtils } from '../../utils/deviceUtils';
import { Features } from '../../../models/enums/Consts';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';
import { LocalStoreService } from '../../storage/implementations/store';

export class SpectateClientSettings extends ClientSettings {
    private _featureAvailbaility: IFeatureAvailability;
    private readonly _deviceUtils: DeviceUtils;
    private readonly _localStoreService: LocalStoreService;

    constructor() {
        super();
        this._deviceUtils = Container.get(DeviceUtils);
        this._featureAvailbaility = Container.get(FeatureAvailabilityToken);
        this._localStoreService = Container.get(LocalStoreService);
    }

    public getClientSettings = (): ISpectateClientSettings => {
        const clientSettingsBase: IClientSettings = super.getClientSettings() as IClientSettings;

        const hasKambiBets = this._window.pageContextManager.getUserData().hasKambiBets;
        const marketingBrandId = this._window.pageContextManager.getBrandData().marketingBrandId;
        const ucfData = Container.get(UCFData);
        const isGeoComplyNDBSEnabled = this._featureAvailbaility.IsFeatureEnabled(
            Features.ENABLE_GC_NDBS,
        );
        const { isMobile } = this._window.pageContextManager.getDeviceData();
        const { geolocationEnabled } = this._window.pageContextManager.getRegulationData();
        const allowLogin = !(isMobile && geolocationEnabled && !isGeoComplyNDBSEnabled);

        return {
            ...clientSettingsBase,
            HasKambiBets: hasKambiBets,
            pageCorrelationId: ucfData?.pageCorrelationId,
            marketingBrandID: marketingBrandId,
            nativeAppType: this._deviceUtils.getNativeAppType(),
            //This field determine if spectate permit login from sbetslip.
            // allowLogin = False , show prompt "Download app"
            // allowLogin = True , show LoginButton
            allowLogin,
        };
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new SpectateClientIntegrationFacade());
    }
}
