import { Deeplinks } from '../../models/enums/Consts';
import { UserDataStoreDeferredObjectToken, WindowToken } from '../../injection-tokens';
import { Container, Service } from 'typedi';
import { LoggerProvider } from '../logger';
import {
    ILogger,
    IOpenAppData,
    IRedirectOrNativeOpenExternalUrl,
    ControlCenterOpenSource,
    AppIdentifiers,
} from '@sparkware/uc-sdk-core';
import { CrossSellService } from '../cross-sell/cross-sell.service';
import { PaymentsService } from '../payments';
import { PlayerService } from '../player/player.service';
import { UserContextToken } from '../../injection-tokens';
import { ClientsFrameworkRegistrationService } from '../external/clients-framework';
import { UserareaService } from '../external/userarea';
import { NavigationService } from './navigation.service';
import { UrlUtils } from '../utils/urlUtils';
import { INativeService } from '../native/models/INativeService';
import { IAppLauncher } from '../app-launcher/models/IAppLauncher';
import { LoaderManager } from '../../loaders/LoaderManager';
import { PendingService } from '../pending/pending.service';
import DeferredObject from '../../../Modules/Utils/DeferredObject';
import { ISessionUserData } from '../session-manager/interfaces/ISessionUserData';

@Service()
export class Navigation {
    private readonly _logger: ILogger;
    private readonly _window: Window;
    private readonly _registrationService: ClientsFrameworkRegistrationService;
    private readonly _urlUtils: UrlUtils;
    private readonly _navigationService: NavigationService;
    private readonly _userAreaService: UserareaService;
    private readonly _pendingService: PendingService;
    private readonly _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;

    private get _appLauncherPromise(): Promise<IAppLauncher> {
        return LoaderManager.Instance.AppLauncherLoader.Instance;
    }

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('Navigation');
        this._window = Container.get(WindowToken);
        this._registrationService = Container.get(ClientsFrameworkRegistrationService);
        this._urlUtils = Container.get(UrlUtils);
        this._navigationService = Container.get(NavigationService);
        this._userAreaService = Container.get(UserareaService);
        this._pendingService = Container.get(PendingService);
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
    }

    public HandleDeeplinks = async (url?: string) => {
        try {
            await Promise.all([
                this._userDataStoreDeferredObject.promise,
                this._window.dataStoreRefreshDO.promise,
            ]);
            const userContext = Container.get(UserContextToken);

            const parsedUrl = new URL(url, this._window.origin);

            const isAuthenticated = userContext.IsAuthenticated;
            const deepLink = parsedUrl?.searchParams.get('deeplink')?.toString().toLowerCase();
            const promo = parsedUrl?.searchParams.get('promo');

            this._logger.debug(`HandleDeeplinks: Deeplink is ${deepLink}`);

            switch (deepLink) {
                case Deeplinks.OpenLogin:
                    this._navigationService.openLogin(isAuthenticated);
                    break;
                case Deeplinks.OpenNrs:
                    this._navigationService.openNRS(isAuthenticated, promo);
                    break;
                case Deeplinks.OpenNrsar:
                    if (!isAuthenticated) {
                        this._registrationService.Open(990, 722, '', 'ar');
                    }
                    break;
                case Deeplinks.OpenCasino:
                    if (isAuthenticated) {
                        const crossSellService = Container.get(CrossSellService);

                        crossSellService.CasinoAutoLogin();
                    }
                    break;
                case Deeplinks.OpenLiveCasino:
                    if (isAuthenticated) {
                        const crossSellService = Container.get(CrossSellService);

                        crossSellService.AutoLoginToLiveDealer();
                    }
                    break;
                case Deeplinks.OpenVegas:
                    if (isAuthenticated) {
                        const crossSellService = Container.get(CrossSellService);

                        crossSellService.GamesAutoLogin();
                    }
                    break;
                case Deeplinks.OpenPoker:
                    if (isAuthenticated) {
                        const crossSellService = Container.get(CrossSellService);

                        crossSellService.PokerAutoLogin();
                    }
                    break;
                case Deeplinks.OpenCashier:
                    if (isAuthenticated) {
                        const paymentsService = Container.get(PaymentsService);

                        paymentsService.OpenCashier();
                    }
                    break;
                case Deeplinks.OpenMyChallenges:
                    if (isAuthenticated) {
                        const playerService = Container.get(PlayerService);
                        playerService.openMyAccountPlayersLounge();
                    }
                    break;
                case Deeplinks.LoginToCashier:
                    this._navigationService.loginToCashier(userContext.IsAuthenticated, promo);
                    break;
                case Deeplinks.OpenControlCenter:
                    this._userAreaService.executeOnload(() => {
                        this._navigationService.openControlCenter({
                            source: ControlCenterOpenSource.Email,
                        });
                    });
            }

            if (deepLink) {
                const newUrl = this._urlUtils.removeParamsFromQuery(
                    this._window.location.href,
                    'deeplink',
                );
                this._window.history.replaceState({}, this._window.document.title, newUrl);
            }
        } catch (error) {
            this._logger.error(
                'HandleDeeplinks: Error while executing deeplink actions prerequisites.',
                error,
            );
        }
    };

    public onOpenApp = async (data: IOpenAppData) => {
        const pendingValue = 2; // todo - why this is an int? should be changed to a string of appID
        if (!data?.appID) {
            this._logger.error('[onOpenApp] missing appID');
            return;
        }
        const appID: any = data.appID;
        if (appID === pendingValue) {
            await this._pendingService.showPending();
        } else {
            const appLauncher = await this._appLauncherPromise;
            const openAppData = data as IOpenAppData;
            void appLauncher.onOpenApp(openAppData);
        }
    };

    public async redirectOrNativeOpenExternalUrl(data: IRedirectOrNativeOpenExternalUrl) {
        this._logger.debug('navigation - redirectOrNativeOpenExternalUrl triggered');
        if (!data && !data.url) {
            this._logger.error('RedirectOrNativeOpenExternalUrl - url is empty');
            return;
        }

        const nativeService = await this._nativeServicePromise;
        if (nativeService) nativeService.openExternalLink(data.url);
        else this._urlUtils.redirectToUrl(data.url);
    }
}
