import Container from 'typedi';
import { LoggerProvider } from '../../logger';
import { IFeatureAvailability } from './feature-availability-interface';
import { FeatureValues } from '../../action-handler/enums';
import { ILogger } from '@sparkware/uc-sdk-core';
import { WindowToken } from '../../../injection-tokens';

export abstract class FeatureAvailabilityBase implements IFeatureAvailability {
    public GetActiveAbTestFeatures: (featureTestId?: number) => Promise<Array<any>>;
    public ResetActiveFeatures: () => void;

    protected readonly _window: Window;
    protected readonly _logger: ILogger;

    constructor() {
        this._window = Container.get(WindowToken);
        this._logger = Container.get(LoggerProvider).getLogger('FeatureAvailability');
    }

    public FeatureIsEnabled = async (
        featureName: string,
        genericFeatureName?: string,
    ): Promise<boolean> => {
        let feature = this._window.pageContextManager
            .getFeaturesData()
            .features.find(
                (feature) => feature.id === featureName || feature.id === genericFeatureName,
            );

        switch (feature?.value.toLowerCase()) {
            case FeatureValues.Off:
                return false;
            case FeatureValues.On:
                return true;
            case FeatureValues.AB:
                return await this.IsFeatureEnabledABTest(featureName, genericFeatureName);
            default:
                return false;
        }
    };

    public IsFeatureEnabled = (featureName: string, genericFeatureName?: string): boolean => {
        if (!featureName) return false;

        const activeFeatures = this._window.pageContextManager
            .getFeaturesData()
            .features.filter((feature) => feature.value.toLowerCase() === 'on');

        if (!activeFeatures || !activeFeatures.length) return false;

        return activeFeatures?.some(
            (activeFeature) =>
                activeFeature?.id.toString().toLowerCase() === featureName?.toLowerCase() ||
                activeFeature?.id.toString().toLowerCase() === genericFeatureName?.toLowerCase(),
        );
    };

    public IsFeatureEnabledABTest = async (
        abFeatureName: string,
        genericFeatureName?: string,
    ): Promise<boolean> => {
        if (!abFeatureName) return false;

        const abTestActiveFeatures = await this.GetActiveAbTestFeatures();

        if (!abTestActiveFeatures || !abTestActiveFeatures.length) return false;

        return abTestActiveFeatures?.some(
            (abFeature) =>
                abFeature.Name?.toLowerCase() === abFeatureName?.toLowerCase() ||
                abFeature.Name?.toLowerCase() === genericFeatureName?.toLowerCase(),
        );
    };

    public IsFeatureEnabledABTestByProperties = async (abFeatureName: string): Promise<boolean> => {
        if (!abFeatureName) return false;

        const abTestActiveFeatures = await this.GetActiveAbTestFeatures();

        if (!abTestActiveFeatures || !abTestActiveFeatures.length) return false;

        return (
            abTestActiveFeatures
                ?.find((feature) => feature.Name?.toLowerCase() === abFeatureName?.toLowerCase())
                ?.Properties[0]?.Value?.toLocaleLowerCase() == 'true' || false
        );
    };
}
